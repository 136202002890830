import Api from "api/api";
import { infoMessage } from "api/errorHandler";
import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  const [password, setPassword]= useState("");
  const [passwordc, setPasswordc]= useState("");
  const [passwordct, setPasswordct]= useState("");
  const clear=()=>{
    setPassword("");
    setPasswordc("");
    setPasswordct("");
  }
  const updatePassword=()=>{
    if(passwordc!=passwordct){
      infoMessage("Different passwords.");
      return;
    }
    var api= new Api();
    const saved = JSON.parse(localStorage.getItem("user_login_wotrek") || "{}");

    api.changepassword(saved["email"],password,passwordc,clear);
  }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input type="password" className="mt-1.5" value={password}  onChange={(e)=>{setPassword(e.target.value)}}/>
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" value={passwordc}  onChange={(e)=>{setPasswordc(e.target.value)}}/>
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" className="mt-1.5" value={passwordct}  onChange={(e)=>{setPasswordct(e.target.value)}}/>
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={updatePassword}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
