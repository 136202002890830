import SectionHero2 from "components/SectionHero2/SectionHero2";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHeroHome2 from "components/SectionHero/SectionHeroHome2";
import Api from "api/api";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-experiences?term=New%20York,%20NY,%20USA&lat=40.7127753&lng=-74.0059728",
    name: "New York",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-experiences?term=Singapore&lat=1.352083&lng=103.819836",
    name: "Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-experiences?term=Paris,%20France&lat=48.856614&lng=2.3522219",
    name: "Paris",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-experiences?term=London,%20UK&lat=51.5072178&lng=-0.1275862",
    name: "London",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-experiences?term=Tokyo,%20Japan&lat=35.6761919&lng=139.6503106",
    name: "Tokyo",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "6",
    href: "/listing-experiences?term=Maldives&lat=3.202778&lng=73.22068",
    name: "Maldives",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];


function PageHome() {

  const [cats, setCats]= useState([]);
  const [catsgeo, setCatsGeo]= useState([]);

  const [user_location, setUserLocation]=useState(null);
  const setUserLocationF=(datao: any)=>{
    setUserLocation(datao);
    if(cats.length==0){
      var api= new Api();
    
    api.getGeoRecomendations("en",datao["join"],"geos",setCatsGeo);
    api.getGeoRecomendationsV("en",datao["join"],"attractions",setCats);

     }

  }
  useEffect(() => {
    
    if(user_location==null){

      var api= new Api();
      api.getGeolocation(setUserLocationF);
      
    }
   
    return () => {
      
    };
  }, []);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>WoTrek</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHeroHome2 className="pt-10 lg:pt-20 pb-16" />
        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

    

   
      
        {/* SECTION 1 */}
        {(cats.length>0)&&  <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
         <SectionSliderNewCategories
            categories={cats}
            categoryCardType="card5"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that wotrek recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>}



        {/* SECTION */}
        {(catsgeo.length>0)&&<SectionGridCategoryBox categories={catsgeo}
            />}

      

       
      
      </div>
    </div>
  );
}

export default PageHome;
