import React, { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import Api from "api/api";
import FlightCard, { FlightData } from "components/FlightCard/FlightCard";
import { LocationDataType } from "data/types";
import LocationCardH from "components/StayCardH/LocationCardH";
import { idText, isTemplateExpression } from "typescript";
import AnyReactComponentFinal from "components/AnyReactComponent/AnyReactComponentFinal";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);


export interface Planning {
  budget?: string;
  datefrom?: string;
  dateto?: string;
  email?: string;
  ori_kiwi?: string;
  primary_category?: string;
  _id?:{
    $oid: string;
  }


  ori?: {
    lat: string;
    lng: string;
    name: string ;

  };

  flights?: {
    data?: FlightData[];
  };
  locations?: {
    experiences?: LocationDataType[];
    hotel?: LocationDataType;
    lat:  number;
    lng: number;
    name: string ;
    loc_ki?: string ;
  }[];
  route?: LocationDataType[];



  data?: LocationDataType[];
}
export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [data, setData] = useState<Planning>({});
  const [calldata, setCallData] = useState(false);

  useEffect(() => {
    var api= new Api();
    var queryString = "?"+window.location.hash.split('?')[1];

    
    if(calldata==false){

      api.getplanning(queryString, setData);
      setCallData(true);
      
    }
   
    
    
    return () => {
      
    };
  }, []);
  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <br/>
        <br/>
        <br/>

        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
        <div className={`mb-12 lg:mb-16 `}>
      <h6 className="text-4xl font-semibold">Personalized Plan</h6>
    </div>
    {data.flights?.data&&data.flights?.data.map((item, index) => (
          <FlightCard key={index} data={item} type={"single-trip"} />
        ))}
          <div className="grid grid-cols-1 gap-8">
         
        {data.flights?.data==undefined&&<h5>No transportations found.</h5>}
        <br/>
          </div>

          <div className="grid grid-cols-1 gap-8">
{ data.locations&&data.locations.length==0&&<h5>No elements found.</h5>}
            {data.locations&&data.locations.map((item) =>{
              
              return <div
              key={item.name}
             
              
              >
           
 <div className={`mb-12 lg:mb-16 `}>
      <h6 className="text-4xl font-semibold">{item.name}</h6>
      {"Location"}
    </div>
             {  item.hotel&&<div
                 onMouseEnter={() => setCurrentHoverID((_) => item.name)}
                 onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <LocationCardH data={item.hotel} />
              </div>}

              { (item.experiences&&item.experiences.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.name)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <LocationCardH data={item} />
              </div>)))}

              </div>
          
          })}
          </div>
         
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
          />
            </div>
            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}

            {data.locations&&data.locations.length>0&&<GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
              }}
              defaultZoom={10}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={{lat:( data.locations[0].lat), lng: (data.locations[0].lng)}}
            >
                {data.route?.map((item) => (
              <AnyReactComponentFinal
                  isSelected={currentHoverID === item.name }
                  key={item.name }
                  lat={item.latitud }
                  lng={item.longitud}
                  experiences={item}
              />
              ))}
            </GoogleMapReact>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
