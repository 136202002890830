import React, {useState, useEffect} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp, { PageSignUpProps } from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import PageConstraints from "containers/PageAddListing1/PageConstraints";
import PageAddMood from "containers/PageAddListing1/PageAddMood";
import {useHistory} from 'react-router-dom';
import Api from "api/api";
import DetailPage from "containers/ListingDetailPage/DetailPage";
import { HashRouter } from 'react-router-dom';

import ReactGA from 'react-ga4';
  const TRACKING_ID = "G-6C13G03R93"; // OUR_TRACKING_ID
 
const Routes = () => {
  const history = useHistory();
  ReactGA.initialize(TRACKING_ID);

  const [user_login, setUser_login]=useState(null);
  const [checklogin, setlogin]=useState(false);
 // const [user_location, setUserLocation]=useState(null);
  
  const pages: Page[] = [
 
    //
  //  {path: "/listing-experiences-map",component: ListingExperiencesMapPage,},
    //
  //  { path: "/listing-car", component: ListingCarPage },
  //  { path: "/listing-car-map", component: ListingCarMapPage },
  //  { path: "/listing-car-detail", component: ListingCarDetailPage },
    //
   // { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  //  { path: "/listing-real-estate", component: ListingRealEstatePage },

 //   { path: "/listing-stay", component: ListingStayPage },
   //   { path: "/listing-stay-detail", component: ListingStayDetailPage },
      //
 //   { path: "/author", component: AuthorPage },
  
  //  { path: "/account-billing", component: AccountBilling },
    
  //  { path: "/blog", component: BlogPage },
 //   { path: "/blog-single", component: BlogSingle },
    //
    { path: "/", exact: true, component:(user_login==null) ?() => (<PageHome2/>):() => (<PageHome/>)},
    { path: "/listing-experiences", component: ListingExperiencesPage,},
    { path: "/location",component: DetailPage, },
 { path: "/listing-flights", component: ListingFlightsPage },
    { path: "/detail-planning", component: ListingStayMapPage },
    { path: "/add-constraints", component: PageConstraints },
    { path: "/add-mood", component: PageAddMood },
    { path: "/contact", component: PageContact },
    { path: "/about", component: PageAbout },
    { path: "/account", component: AccountPage },
    { path: "/account-password", component: AccountPass },
    { path: "/account-savelists", component: AccountSavelists },
    { path: "/signup", component:(user_login==null) ?() => (<PageHome2/>):() => (<PageHome/>)},
    { path: "/login", component:(user_login==null) ?() => (<PageHome2/>):() => (<PageHome/>)},
   // { path: "/subscription", component: PageSubcription },
    //
  ];
  
  const pageswlogin: Page[] = [
    { path: "/", exact: true, component:(user_login==null) ?() => (<PageHome2/>):() => (<PageHome/>)},
    { path: "/listing-experiences", component: ListingExperiencesPage,},
    { path: "/location",component: DetailPage, },
    { path: "/listing-flights", component: ListingFlightsPage },
    { path: "/detail-planning", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/add-constraints", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/add-mood", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/contact", component: PageContact },
    { path: "/about", component: PageAbout },
    { path: "/account", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/account-password", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/account-savelists", component:  () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />) },
    { path: "/signup", component: () => (<PageSignUp setUserLoginFinal={setUser_login} setCheck={setlogin} />)  },
    { path: "/login", component:() => (<PageLogin setUserLoginFinal={setUser_login} setCheck={setlogin} />)  },
   // { path: "/subscription", component: PageSubcription },
    //
  ];


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
//+ window.location.search 
    if(checklogin==false){

      const saved = localStorage.getItem("user_login_wotrek");
      if(saved!=null){
        setUser_login(JSON.parse(saved));
      }
      setlogin(true);
    }
  
    
  });

  const logout=()=>{
    console.log("SALIDA");
    setUser_login(null);
    const saved = localStorage.removeItem("user_login_wotrek");
    history.push('/'+ history.location.search);

  }

  return (
    <HashRouter basename="/">
      <ScrollToTop />
      <SiteHeader user_login={user_login} logout={logout}/>

      <Switch>
        {user_login&&pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })} {user_login==null&&pageswlogin.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </HashRouter>
  );
};

export default Routes;
function setCheck(setUserLogin: any, setUser_login: any, setCheck: any, arg3: undefined): React.ComponentType<Object> {
  throw new Error("Function not implemented.");
}

function PropsSignUp(className: any, arg1: string, setUser_loginFinal: any, setUser_login: any, setCheck: (setUserLogin: any, setUser_login: any, setCheck: any, arg3: undefined) => React.ComponentType<Object>, setlogin: React.Dispatch<React.SetStateAction<boolean>>): React.PropsWithChildren<import("containers/PageSignUp/PageSignUp").PageSignUpProps> {
  throw new Error("Function not implemented.");
}

function className(className: any, arg1: string, setUser_loginFinal: any, setUser_login: any, setCheck: (setUserLogin: any, setUser_login: any, setCheck: any, arg3: undefined) => React.ComponentType<Object>, setlogin: React.Dispatch<React.SetStateAction<boolean>>): React.PropsWithChildren<import("containers/PageSignUp/PageSignUp").PageSignUpProps> {
  throw new Error("Function not implemented.");
}

