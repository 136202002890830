import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import ListingStayDetailPage from "./ListingStayDetailPage";
import ListingExperiencesDetailPage from "./ListingExperiencesDetailPage";
import Api from "api/api";

export interface ListingExperiencesDetailPageProps {
  className?: string;
}


const DetailPage: FC<ListingExperiencesDetailPageProps> = ({
  className = "",
}) => {
  const [data, setData] = useState(null);
  const [type, setType] = useState("Stays");
 
  useEffect(() => {
    var api= new Api();
    var queryString = "?"+window.location.hash.split('?')[1];

    
    if(data==null){

      api.getLocationDetail(queryString, setData);
      
    }
   
    
    
    return () => {
      
    };
  }, []);
  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
     {(data!=null&&(
       <ListingStayDetailPage data={data}/>
     ))}
    </div>
  );
};

export default DetailPage;
