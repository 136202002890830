import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC, useState, useEffect } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHeroArchivePageW from "components/SectionHeroArchivePage/SectionHeroArchivePageW";
import imagePng from "images/hero-right2.png";
import SectionGridFilterCardStay from "./SectionGridFilterCardStay";
import { useParams } from 'react-router';
import Api from "api/api";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHeroHome2 from "components/SectionHero/SectionHeroHome2";

export type SearchTab = "Stays" | "Experiences" | "Flights";

export interface ListingExperiencesPageProps {
  className?: string;
}


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-experiences?term=New%20York,%20NY,%20USA&lat=40.7127753&lng=-74.0059728",
    name: "New York",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-experiences?term=Singapore&lat=1.352083&lng=103.819836",
    name: "Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-experiences?term=Paris,%20France&lat=48.856614&lng=2.3522219",
    name: "Paris",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-experiences?term=London,%20UK&lat=51.5072178&lng=-0.1275862",
    name: "London",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-experiences?term=Tokyo,%20Japan&lat=35.6761919&lng=139.6503106",
    name: "Tokyo",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "6",
    href: "/listing-experiences?term=Maldives&lat=3.202778&lng=73.22068",
    name: "Maldives",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];
const ListingExperiencesPage: FC<ListingExperiencesPageProps> = ({
  className = "",
  
}) => {
  const [data, setData] = useState("Experiences");
  const tabsw: SearchTab[] = ["Experiences","Stays"];

  const [dataExp, setDataExp] = useState([]);
  const [dataAco, setDataAco] = useState([]);
  const [search, setSearch] = useState(false);
  const [term, setTerm] = useState("");
  const [typeData, setTypeData] = useState("Experiences");

  useEffect(() => {
    var api= new Api();
    var queryString = "?"+window.location.hash.split('?')[1];

    if(dataExp.length==0&& search==false){

      api.getExperiencesTerm(queryString, setDataExp);
      setSearch(true);
      
    }
    if(dataAco.length==0&& search==false){

      api.getAccomodationsTerm(queryString, setDataAco);
      setSearch(true);

      
    }
    const urlParams = new URLSearchParams(queryString);
    var typedata = urlParams.get('type');

    if(term==""){
      var product = urlParams.get('term');

      if(product!=null){
        setTerm(product);

      }
      

    }
    if(typedata!=null){
      setTypeData(typedata);

    }
    return () => {
      
    };
  }, []);
  return (
    <div
      className={`nc-ListingExperiencesPage relative overflow-hidden ${className}`}
      data-nc-id="ListingExperiencesPage"
    >
      <Helmet>
        <title>WoTrek</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
      <SectionHeroHome2 className="pt-10 lg:pt-20 pb-16" />

        {/* SECTION HERO */}
        <div  className="relative py-16">

        <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {term!=""?term:"Columbus" } 
          </h2>

        <br/>
          <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabsw.map((tab) => {
          const active = tab === typeData;
          return (
            <li
              onClick={() => setTypeData(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>

   
    </div>

     {/* SECTION */}
   { (typeData=="Experiences")&& <SectionGridFilterCard className="pb-24 lg:pb-32" data={dataExp}/>}

        {/* SECTION */}
   {(typeData=="Stays")&&     <SectionGridFilterCardStay className="pb-24 lg:pb-32" data={dataAco}/>}

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />
        </div>

    
      </div>
    </div>
  );
};

export default ListingExperiencesPage;
