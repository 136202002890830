import { React } from "react";
import axios from "axios";
import * as rax from "retry-axios";
import { commonError, fatalError, infoMessage } from "./errorHandler";

const server_t = "http://127.0.0.1:5000";
const server = "https://flask-service.9m11tlsoo3s4a.us-east-1.cs.amazonlightsail.com/";


rax.attach();
export default class Api {
  constructor() {
    this.token = "";
  }


get_server(){
  if (server.endsWith("/")){
    return server.slice(0, -1);
  }
  return server
}
  //Create user -  Ingresa los siguientes datos
  async register(user, setUser, setLogin) {
    var url = this.get_server() + "/register";
    
    var req = user;
  
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["login"]==true){
        const saved = localStorage.setItem("user_login_wotrek", JSON.stringify(data));

        setUser(data);

      }
      setLogin(true);
    })
    .catch((e) => commonError(e.toString()));
  }
  async login(user, setUser, setLogin) {
    var url = this.get_server() + "/login";
    
    var req = user;
  
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["login"]==true){
        const saved = localStorage.setItem("user_login_wotrek", JSON.stringify(data));

        setUser(data);

      }else{
        infoMessage("Incorrect credentials. Try again.");
      }
      setLogin(true);
    })
    .catch((e) => commonError(e.toString()));
  }

  async changepassword(email, pass, newpass, clear) {
    var url = this.get_server() + "/change-password";
    
    var req ={
      "email": email,
  "password_old": pass,
  "password_new": newpass
    };
  
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["login"]==true){
        infoMessage("Password changed.");

      }else{
        infoMessage("Incorrect password.");
      }
      clear();
    })
    .catch((e) => commonError(e.toString()));
  }


  async loginFacebook(user, setUser, setLogin) {
    var url = this.get_server() + "/loginFacebook";
    
    var req = user;
  
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["login"]==true){
        const saved = localStorage.setItem("user_login_wotrek", JSON.stringify(data));

        setUser(data);

      }else{
        infoMessage("Incorrect credentials. Try again.");
      }
      setLogin(true);
    })
    .catch((e) => commonError(e.toString()));
  }
  async register_newsletter(user) {
    var url = this.get_server() + "/register_newsletter";
    
    var req = user;
  
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["register"]==true){
        
        infoMessage("Success registry");
      }else{
        infoMessage("User already register.");
      }
    })
    .catch((e) => {}//commonError(e.toString())
    );
  }


  getGeolocation(setLocation) {
    var url =  "https://geolocation-db.com/json/";
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
       
let data=response.data;
setLocation({
  "country_code":data["country_code"],
  "country_name":data["country_name"],
  "city":data["city"],
  "postal":data["postal"],
  "latitude":data["latitude"],
  "longitude":data["longitude"],
  "state":data["state"],
  "join": data["latitude"]+"%2C"+data["longitude"]
});
console.log({
  "country_code":data["country_code"],
  "country_name":data["country_name"],
  "city":data["city"],
  "postal":data["postal"],
  "latitude":data["latitude"],
  "longitude":data["longitude"],
  "state":data["state"],
  "join": data["latitude"]+"%2C"+data["longitude"]
});
      })
      .catch((e) => {}//commonError(e.toString())
      );
  }





  getGeoRecomendations(lan,pos,category, setData) {
    var url =  this.get_server() + "/getrecomendationsnearby?lan="+lan+"&pos="+pos+"&cat="+category;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);

      })
      .catch((e) => {}//commonError(e.toString())
      );
  }



  getGeoRecomendationsV(lan,pos,category, setData) {
    var url =  this.get_server() + "/getrecomendationsnearbyV?lan="+lan+"&pos="+pos+"&cat="+category;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);

      })
      .catch((e) => {}//commonError(e.toString())
      );
  }



  getExperiencesTerm(addurl, setData) {
    var url =  this.get_server() + "/getExperiencesTerm"+addurl;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);

      })
      .catch((e) => {}//commonError(e.toString())
      );
  }

  getAccomodationsTerm(addurl, setData) {
    var url =  this.get_server() + "/getAccomodationsTerm"+addurl;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);

      })
      .catch((e) => {}//commonError(e.toString())
      );
  }


  getFlightsSearch(addurl, setData) {
    var url =  this.get_server() + "/getFlightsSearch"+addurl;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }




    getGeoRecomendations(lan,pos,category, setData) {
    var url =  this.get_server() + "/getrecomendationsnearby?lan="+lan+"&pos="+pos+"&cat="+category;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);

      })
      .catch((e) => {}//commonError(e.toString())
      );
  }


  getLocationDetail(addurl, setData) {
    var url =  this.get_server() + "/getlocationdetail_final"+addurl;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data);


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }


  getsocialMediaAnalizedCall(email, setData, setSocialMediaAnalizedCall) {
    var url =  this.get_server() + "/getdatauserfacebookanalyzed?email="+email;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["result"]);
        setSocialMediaAnalizedCall(true);


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }



  getdatauserfacebook(email,accesstoken, setData) {
    var url =  this.get_server() + "/getdatauserfacebook?email="+email+ "&access_token="+accesstoken;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(true);
        window.location.reload();


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }



  async planning(data, final) {
    var url = this.get_server() + "/genatePlan";
    console.log("data");

      console.log(data);
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
       // Authorization: "Bearer " + this.token,
      },
    }).then((response) => {
      console.log("GetInfo");
      console.log(response.data);
     var data=response.data;
      if(data["id"]!= null){
        final(data["id"]);
      }
    })
    .catch((e) => commonError(e.toString()));
  }




  getplanning(url, setData) {
    var url =  this.get_server() + "/getPlan"+url;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data);


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }




  getPlanningEmail(email, setData) {
    var url =  this.get_server() + "/getPlanEmail?email="+email;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
      //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        setData(response.data["data"]);


      })
      .catch((e) => {}//commonError(e.toString())
      );
  }
}