import { Tab } from "@headlessui/react";
import Api from "api/api";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import ExperiencesCardPlanningFinal from "components/ExperiencesCard/ExperiencesCardFinalPlanning";
import StayCard from "components/StayCard/StayCard";
import { Planning } from "containers/ListingStayPage/SectionGridHasMap";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";

const AccountSavelists = () => {
  
  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Plannings</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          
        {data.length>0&&<div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {data.map(
                    (stay) => (
                    <ExperiencesCardPlanningFinal  dataPlanning={stay} />
                    )
                  )}
                </div>}

                {data.length==0&&<h5>No plannings generated.</h5>}
               
         
        </div>
      </div>
    );
  };
  const [data, setData]= useState<Planning[]>([]);
  const [calldata, setcallData]= useState(false);

  useEffect(() => {
    if (calldata==false) {
      const saved = JSON.parse(localStorage.getItem("user_login_wotrek") || "{}");
      var api= new Api();
      api.getPlanningEmail(saved["email"],setData);
      setcallData(true);

  }
  }, []);
  return (
    <div>
      <CommonLayout>
        {renderSection1()}
        
        
        </CommonLayout>
    </div>
  );
};

export default AccountSavelists;
