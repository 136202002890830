import React, { FC } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps, FlightData } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionGridFilterCardProps {
  className?: string;
  number?:  Number;
  data? : FlightData[]
}



const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
  number =0,
}) => {
 
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={data[0].cityFrom+ " - "+ data[0].cityTo}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {data.length} flights
            <span className="mx-2">·</span>
            round trip
            <span className="mx-2">·</span>1 Guests
          </span>
        }
      />
      <div className="mb-8 lg:mb-11">
       {/* <TabFilters />*/}
      </div>
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {data.map((item, index) => (
          <FlightCard key={index} data={item} type={"round-trip"} />
        ))}

        <div className="flex mt-12 justify-center items-center">
        {/*  <ButtonPrimary loading>Show more</ButtonPrimary>*/ }
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
