import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { LocationDataType, StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface LocationCardHProps {
  className?: string;
  data?: LocationDataType;
}


const LocationCardH: FC<LocationCardHProps> = ({
  className = "",
  data = {
    "id": "1234567",
"name": "Beach House in Collingwood",
"latitud": 55.9607277,
"longitud": 36.2172614,
"description": "The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental service and a car rental service are available at the accommodation, while cycling can be enjoyed nearby.",
"web_url": "",
"address": "San Diego, CA, United States of America (SAN-San Diego Intl.)",
"zone_geo" : "Tokyo, Jappan",
"timeZone": "",
"photos": [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
],
"rating": 4.5,
"count_reviews":23,
"phone": "",
"avalaiblePrice": "",
"price": "$119",
"avalaibleDisponibility": "",
"disponibility": "",
"category": "Wooden house",
"subcategory": "",
"trip_types": "",
"groups": [],
"url_reservation": "http://www.w3schools.com",
"reviews":[
  {
    name: "Cody Fisher",
    date: "May 20, 2021",
    comment:
      "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    starPoint: 5,
  }
]
  },
}) => {
  const {
    id,
    name,
    latitud,
    longitud,
    description,
    web_url,
    address,
    zone_geo,
    timeZone,
    photos,
    rating,
    count_reviews,
    phone,
    avalaiblePrice,
    price,
    avalaibleDisponibility,
    disponibility,
    category,
    subcategory,
    trip_types,
    groups,
    url_reservation,
    source,
    duration,
    originalData,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={photos}
          uniqueID={`LocationCardH_${id}`}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" />
        {avalaibleDisponibility && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div>
        <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {zone_geo}
            </span>
          </div>
        </div>
       
       
      </div>
      <br/>
      <br/>
         <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {description}
            </span>
      </div>
      
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {category} in {address}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {category && <Badge name={category} color={(category=="hotels")?"blue":"green"} />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <StartRating reviewCount={count_reviews} point={rating} />
          <span className="text-base font-semibold text-secondary-500">
            {price}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              
              {(category=="hotels")?"/night":"/total"}
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-LocationCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="LocationCardH"
    >
      <Link to={"/location?type="+source+"&id="+id} className="flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default LocationCardH;
