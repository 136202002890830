import React, { FC , useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape } from "react-dates";
import moment from "moment";
import useWindowSize from "hooks/useWindowResize";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import LocationInput from "components/HeroSearchForm/LocationInput";
import Api from "api/api";
import FacebookLogin from "react-facebook-login";
import { TemplateIcon } from "@heroicons/react/solid";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { TimeRage } from "components/HeroSearchForm/RentalCarSearchForm";
import CommonLayoutFinal from "./CommonLayoutFinal";

export interface PageConstraintsProps {}

const PageConstraints: FC<PageConstraintsProps> = () => {
  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center">
        <input
          defaultChecked={defaultChecked}
          id={id + name}
          name={name}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderNoInclude = (text: string, ind: number) => {
    return (
      <div className="flex items-center justify-between py-3">
        <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
          {text}
        </span>

        <button className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer" onClick={(e)=>{
var temp= [];
var temp2= [];



for(var i=0; i<locations.length;i++){
  if(i!=ind){
    temp.push(locations[i]);
    temp2.push(locationsdata[i]);

  }
}

console.log(temp);

setLocations(temp);
setLocationsdata(temp2);
setLocationInputValue("");

        }}>{ " "}</button>
      </div>
    );
  };
  const [dates, setDates] = useState<moment.Moment[]>([]);

  function handleDateChange(date: moment.Moment) {


   if(dates.length>0){
    const wasPreviouslyPicked = dates.some((d) => d.isSame(date));
    if (wasPreviouslyPicked) {
      setDates((previousDates) => previousDates.filter((d) => !d.isSame(date)));
    } else {
      setDates((previousDates) => [...previousDates, date]);
    }
   } 
   else{
    setDates([date]);

   }
    
  }
  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 600) {
      return undefined;
    }
    return 56;
  };

  const [locations, setLocations] = useState<string[]>([]);
  const [locationsdata, setLocationsdata] = useState<Object[]>([]);

  const [dateValue, setdateValue] = useState<moment.Moment | null>(null);
  const [locationInputValue, setLocationInputValue] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [socialMediaAnalized, setSocialMediaAnalized] = useState(false);
  const [socialMediaAnalizedCall, setSocialMediaAnalizedCall] = useState(false);
  const [presupuesto, setPresupuesto] = useState<string>("0");
  const [email, setEmail] = useState<string>("");

  const [originlocationInputValue, setOriginLocationInputValue] = useState("");
  const [originlocationInputValueF, setOriginLocationInputValueF] = useState<Object>({});


  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(4, "days"),
  });
  
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");


  const changesLocation=(response: string) =>{
    setLocationInputValue(response);

} 

const changesLocationLatLong=(response: string) =>{


  var temp_locations : Object[]=locationsdata;

  var divi =response.split("&");
  var term=divi[0].split("=")[1];
  var lat=divi[1].split("=")[1];
  var lng=divi[2].split("=")[1];
  var data ={
    "term": term,
    "lat": lat,
    "lng": lng
  }
  temp_locations.push(data);
  var temp_locations2=locations;
  temp_locations2.push(term);

  setLocations(temp_locations2);
  setLocationsdata(temp_locations);

  

} 

const changesOriginLocationLatLong=(response: string) =>{



  var divi =response.split("&");
  var term=divi[0].split("=")[1];
  var lat=divi[1].split("=")[1];
  var lng=divi[2].split("=")[1];
  var data ={
    "term": term,
    "lat": lat,
    "lng": lng
  }
  setOriginLocationInputValueF(data);
  

}
  //
  const responseFacebook=(response:any) =>{

    const saved = JSON.parse(localStorage.getItem("user_login_wotrek") || "{}");

      var api= new Api();
      api.getdatauserfacebook(saved["email"],response["accessToken"], setSocialMediaAnalized );


}

  useEffect(() => {
    if (socialMediaAnalizedCall==false) {
        var api= new Api();

        const saved = JSON.parse(localStorage.getItem("user_login_wotrek") || "{}");
        setEmail(saved["email"]);
        api.getsocialMediaAnalizedCall(saved["email"],setSocialMediaAnalized , setSocialMediaAnalizedCall);
    }
  }, []);
if(socialMediaAnalizedCall==false){

  return <div></div>

}
if(socialMediaAnalizedCall&&socialMediaAnalized==false){
  return (        
  <CommonLayout
    index="01"
    backtHref="/add-constraints"
    nextHref="/add-mood"
  >     
      <div className="space-y-8">
      

        {/* ----------- */}
        <h2 className="text-2xl font-semibold">Analyze Socia Media</h2>  
        <br/>
        <br/>
        <FacebookLogin
        textButton= "Analize Facebook"
    appId="535251518155138"
    autoLoad={false}
    fields="name,email,picture,birthday,gender,hometown"
    scope="public_profile,user_birthday,user_hometown,user_likes,user_posts,user_gender,user_age_range,email"
    callback={responseFacebook}
  />
  
  </div>
  </ CommonLayout>
  );
}


  return (
    <CommonLayoutFinal
      index="01"
      backtHref="/add-constraints"
      nextHref="/detail-planning" locationsdata={locationsdata} originlocationInputValueF={originlocationInputValueF} presupuesto={presupuesto} dateRangeValue={dateRangeValue} email={email}    >
      <>
       
        <div className="space-y-8">
        

          {/* ----------- */}
          <h2 className="text-2xl font-semibold">Destinations</h2>
          <div className="flow-root">
            <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
              {locations.length==0&& renderNoInclude("To surprise", 0)}
              {locations.length>0&& locations.map((e, ind)=>{
return renderNoInclude(e, ind);
              })
              
              
              }

            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
            <LocationInput
             defaultValue={locationInputValue}
             onChange={(e) => changesLocation(e)}
             onInputDone={() => {}}
             onUrlDone={(e) => changesLocationLatLong(e)} />
            <ButtonPrimary className="flex-shrink-0" onClick={()=>{setLocationInputValue("");}}>
              <i className="text-xl las la-plus"></i>
              <span className="ml-3">Add destination</span>
            </ButtonPrimary>
          </div>


          
        </div>
        <div className="space-y-8">
        

          {/* ----------- */}
          <h2 className="text-2xl font-semibold">Origin</h2>
          <LocationInput
             defaultValue={originlocationInputValue }
             onChange={(e) => setOriginLocationInputValue(e)}
             onInputDone={() => {}}
             onUrlDone={(e) => changesOriginLocationLatLong(e)} />
           


          
        </div>
        <div>
          <h2 className="text-2xl font-semibold">Budget</h2>
        </div>
      
        <div className="space-y-8">
      
          <FormItem label="Máximun budget">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">$</span>
              </div>
              <Input className="!pl-8 !pr-10" placeholder="0" onChange={(e) => setPresupuesto(e.currentTarget.value)} value={presupuesto}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">USD</span>
              </div>
            </div>
          </FormItem>
       
        </div>


        <div>
          <h2 className="text-2xl font-semibold">Set your availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Editing your calendar is easy—just select a date range of your trip.
          </span>
        </div>

        <div className="nc-SetYourAvailabilityData">
        <DayPickerRangeController
              startDate={dateRangeValue.startDate}
              endDate={dateRangeValue.endDate}
              onDatesChange={(date) => setDateRangeValue(date)}
              focusedInput={focusedInputSectionCheckDate}
              onFocusChange={(focusedInput) =>
                setFocusedInputSectionCheckDate(focusedInput || "startDate")
              }
              initialVisibleMonth={null}
              numberOfMonths={1}
              daySize={getDaySize()}
              hideKeyboardShortcutsPanel
            />
        </div>
      </>
    </CommonLayoutFinal>
  );
}
  ;

export default PageConstraints;
