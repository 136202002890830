import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType, LocationDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { Planning } from "containers/ListingStayPage/SectionGridHasMap";

export interface ExperiencesCardPlanningProps {
  className?: string;
  ratioClass?: string;
  data?: LocationDataType;
  size?: "default" | "small";
  dataPlanning: Planning;
}

const DEMO_DATA: LocationDataType = {
  id: "", name: "", address: "", photos: [], rating: 5, count_reviews: 0, price: "", avalaibleDisponibility: "", source: "",
  latitud: 0,
  longitud: 0,
  description: "",
  web_url: "",
  zone_geo: "",
  timeZone: "",
  phone: "",
  avalaiblePrice: "",
  disponibility: "",
  category: "",
  subcategory: undefined,
  trip_types: undefined,
  groups: undefined,
  url_reservation: "",
  duration: undefined,
  originalData: undefined
};

const ExperiencesCardPlanningFinal: FC<ExperiencesCardPlanningProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  ratioClass = "aspect-w-3 aspect-h-3",
  dataPlanning = {
   
  }
}) => {
  
  const getLocation = () => {
    if(dataPlanning!= undefined && dataPlanning.locations!=undefined && dataPlanning.locations.length>0&& dataPlanning.locations[0].experiences!=undefined && dataPlanning.locations[0].experiences.length>0){
      

      return dataPlanning.locations[0].experiences[0] ;

      
    }
    return DEMO_DATA;
  }
  const {
    id,  name,  address,  photos, rating, count_reviews, price, avalaibleDisponibility, source
  } = getLocation();

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`ExperiencesCardPlanning_${id}`}
          ratioClass={ratioClass}
          galleryImgs={photos.length>0? photos:["https://www.svgrepo.com/show/136485/upper-right-arrow-black-symbol.svg"]}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" />
        {avalaibleDisponibility && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>

          <div className="flex items-center space-x-2">
           <Badge name="Plan" color="green" />
           <Badge name={dataPlanning.primary_category} color="yellow" />
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-base" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{"From: "+ dataPlanning.ori?.name}</span>
             { dataPlanning.locations!=undefined&&dataPlanning.locations?.length>0&& <span className="line-clamp-1">{"To: "+(dataPlanning.locations[0].name +( dataPlanning.locations?.length>1?(" + "+(dataPlanning.locations?.length-2)+ " places"):""))}</span>}
           
            </h2>
          </div>
        </div>
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
        <div>
        <span className="line-clamp-1">{"Date From: "+ dataPlanning.datefrom}</span>
          
        </div>
        <div>
        <span className="line-clamp-1">{"Date To: "+ dataPlanning.dateto}</span>

        </div>
            
        <div className="flex justify-between items-center">
       
          <span className="text-base font-semibold">
            {price}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /person
              </span>
            )}
          </span>
          <StartRating reviewCount={count_reviews} point={rating} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCardPlanning group relative ${className}`}
      data-nc-id="ExperiencesCardPlanning"
    >
      <Link to={"/detail-planning?id="+dataPlanning._id?.$oid}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCardPlanningFinal;
