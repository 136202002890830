import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHeroHome2 from "components/SectionHero/SectionHeroHome2";
import Api from "api/api";

export interface ListingFlightsPageProps {
  className?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the Beauty of Brazil ",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "2",
    href: "#",
    name: "Enjoy the Beauty of Paris",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "3",
    href: "#",
    name: "Enjoy the Beauty of Bangkok",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "5",
    href: "#",
    name: "Enjoy the Beauty of Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "4",
    href: "#",
    name: "Enjoy the Beauty of Seoul",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
];

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {

  const [dataFli, setDataFli] = useState([]);
  const [search, setSearch] = useState(false);
  const [term, setTerm] = useState("");
  const [term2, setTerm2] = useState("");

  useEffect(() => {
    var api= new Api();
    var queryString = "?"+window.location.hash.split('?')[1];

    if(dataFli.length==0&& search==false){

      api.getFlightsSearch(queryString, setDataFli);
      setSearch(true);
      
    }
   
    const urlParams = new URLSearchParams(queryString);
    if(term==""){
      var product = urlParams.get('term');

      if(product!=null){
        setTerm(product);

      }
      product = urlParams.get('term2');

      if(product!=null){
        setTerm2(product);

      }

    }
    
    return () => {
      
    };
  }, []);
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>WoTrek || WoTrek</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
       
<SectionHeroHome2 className="pt-10 lg:pt-20 pb-16" tab={"Flights"}
         />
<br/>
<br/>
<br/>
        {/* SECTION */}
       {(dataFli.length>0)&& <SectionGridFilterCard className="pb-24 lg:pb-32" data={dataFli}/>}

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Explore top destination ✈"
          subHeading="Explore thousands of destinations around the world"
          categoryCardType="card4"
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName="ListingFlightsPage-section1"
        />

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default ListingFlightsPage;
