import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-rightv2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Logo from "shared/Logo/Logo";

export interface SectionHeroHome2Props {
  className?: string;
  tab?: "Stays" | "Experiences" | "Flights";
}

const SectionHeroHome2: FC<SectionHeroHome2Props> = ({ className = "", tab="Experiences" }) => {
  return (
    <div >
      <br/>
      <br/>

        <HeroSearchForm currentTab={tab} />
      </div>
  );
};

export default SectionHeroHome2;
