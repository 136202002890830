import Api from "api/api";
import { infoMessage } from "api/errorHandler";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {useHistory} from 'react-router-dom';

export interface CommonLayoutFinalProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  locationsdata: Object[];
  originlocationInputValueF: Object;
  presupuesto: string;
  dateRangeValue: DateRage;
  email: string;

}

const CommonLayoutFinal: FC<CommonLayoutFinalProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
  
  locationsdata,
  originlocationInputValueF,
  presupuesto,
  dateRangeValue,
  email
}) => {
  const history = useHistory();

  const redirectFinal=(id: string)=>{
    history.push('./detail-planning?id='+id);

  }
  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 2
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          <ButtonSecondary href={backtHref}>Go back</ButtonSecondary>
          <ButtonPrimary onClick={()=>{

//Verificar datos
if(originlocationInputValueF=={}){
  infoMessage("Complete the required inputs.");
  return;
}
if(dateRangeValue.startDate && dateRangeValue.endDate && dateRangeValue.endDate.diff(dateRangeValue.startDate, 'days') < locationsdata?.length){
infoMessage("Error. Short date range for selected locations.");
return;
}
infoMessage("Processing order you will be redirected.");

var api= new Api();
var data={
  "email": email,
  "datefrom": dateRangeValue.startDate?.format("DD/MM/YYYY"),
  "dateto": dateRangeValue.endDate?.format("DD/MM/YYYY"),
  "ori": originlocationInputValueF,
  "locations": locationsdata,
  "budget": presupuesto
};
api.planning(data,redirectFinal);


          }}>
            {nextBtnText || "Continue"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayoutFinal;
