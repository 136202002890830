import React from "react";
import { useLocation } from "react-router-dom";
import Header2 from "components/Header/Header";
import Header from "shared/Header/Header";

const SiteHeader = ({user_login= null, logout=()=>{}}) => {
  let location = useLocation();

  
  return (user_login!=null) ? (
    <Header2 logout={logout}/>
  ) : (
    <Header />
  );
};

export default SiteHeader;
